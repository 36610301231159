import * as React from "react"
import { Link } from "gatsby"
import Menu from "./menu"
import "../../assets/sass/example/example.scss";
import { StaticImage } from "gatsby-plugin-image";
//import { Parallax, ParallaxLayer } from '@react-spring/parallax'
//import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { Background, Parallax } from "react-parallax";
import SmoothScrollContainer from "../../components/SmoothScrollContainer";

// markup
const Example1 = () => {
  return (
    <SmoothScrollContainer >
      <Menu></Menu>
      
      <section className="project-header">
        <div className="container">
          <div className="columns">
              <div className="column is-8 desc">
                <p className="project-name">Healthy Eats</p>
                <p>Hassle-free Nutrition solution for busy professionals</p>
              </div>
              <div className="column is-4 serv">
                  <ul>
                    <li>Branding</li>
                    <li>Responsive Web Design</li>
                    <li>Packaging</li>
                  </ul>
              </div>
          </div>
        </div>
    </section>
    
      
    
        <Parallax strength={500} className="hero main-hero" >
            <Background className="hero-body">
              <StaticImage className="bg-img" src="../../images/example/hero.png">
              </StaticImage>
            </Background>
        </Parallax>
    

    
    <section id="logo-section">
      <div className="container">
        <div className="columns is-multiline">
              <div className="column imgbox is-6">
                <StaticImage src="../../images/example/logo.png"></StaticImage>
              </div>
              <div className="column imgbox is-6">
                <StaticImage src="../../images/example/van.png"></StaticImage>
              </div>
              <div className="column is-6">
                  <div className="textbox">
                  <p className="title">Hassle-free Nutrition solution for busy professionals</p>
                  <p className="desc">Fresh, delicious, ready to eat meals delivered to your doorstep. Fresh, delicious, ready to eat meals delivered to your doorstep.</p>
                  </div>
              </div>
              <div className="column imgbox is-6 no-padding">
                <StaticImage src="../../images/example/box.png"></StaticImage>
              </div>
        </div>
      </div>
    </section>
    


    <section id="menu-section">
      <div className="container">
        <div className="columns is-multiline">
              <div className="column imgbox is-8">
                <StaticImage src="../../images/example/menu.png"></StaticImage>
              </div>
              <div className="column is-4">
                  <div className="textbox center">
                  <p className="title">Hassle-free Nutrition solution for busy professionals</p>
                  <p className="desc">Fresh, delicious, ready to eat meals delivered to your doorstep. Fresh, delicious, ready to eat meals delivered to your doorstep.</p>
                  </div>
              </div>
        </div>
      </div>
    </section>
    


    <section id="vid-section">
      <div className="container">
        <div className="columns is-multiline">
              <div className="column is-4">
                  <div className="textbox center">
                  <p className="title">Hassle-free Nutrition solution for busy professionals</p>
                  <p className="desc">Fresh, delicious, ready to eat meals delivered to your doorstep. Fresh, delicious, ready to eat meals delivered to your doorstep.</p>
                  </div>
              </div>
              <div className="column imgbox is-8">
                <div className="vid-showcase">
                  <StaticImage className="one" src="../../images/example/vid2.png"></StaticImage>
                  <StaticImage className="two" src="../../images/example/vid1.png"></StaticImage>
                  <StaticImage className="three" src="../../images/example/vid3.png"></StaticImage>
                </div>
              </div>
        </div>
      </div>
    </section>
    

    <section id="feat-section">
      <div className="container">
        <div className="columns is-multiline">
              <div className="column imgbox is-8">
              <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
              <StaticImage src="../../images/example/features.png"></StaticImage>
              </Parallax>
              </div>
              <div className="column is-4">
                  <div className="textbox center">
                  <p className="title">Hassle-free Nutrition solution for busy professionals</p>
                  <p className="desc">Fresh, delicious, ready to eat meals delivered to your doorstep. Fresh, delicious, ready to eat meals delivered to your doorstep.</p>
                  </div>
              </div>
        </div>
      </div>
    </section>
    

    <section className="hero">
        <div className="hero-body  footer-hero">
        </div>
    </section>
    

    </SmoothScrollContainer>
  )
}

export default Example1
